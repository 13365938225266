import { Component, OnInit } from "@angular/core";
import { IEvent, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent } from "ngx-lightbox";
import { Subscription } from "rxjs";
import { PassengerService } from "../data/passenger/passenger.service";
import * as moment from "moment";

@Component({
  selector: "app-passengers",
  templateUrl: "./passengers.component.html",
  styleUrls: ["./passengers.component.scss"]
})
export class PassengersComponent implements OnInit {
  registrationPeriod: Date[] = null;
  showClearSearchButton = false;
  passengers: any[];
  totalPassengers: number;
  errorMessage: string;
  page: number = 1;
  limit: number = 20;
  offset: number = 0;
  query: string;
  images: any[] = [];
  sortByOptions = [
    {
      displayName: "newer",
      value: "DESC"
    },
    {
      displayName: "older",
      value: "ASC"
    }
  ];
  selectedSortBy = "DESC";
  searchText = "";
  private _subscription: Subscription;

  constructor(
    private passengerService: PassengerService,
    private _lighboxConfig: LightboxConfig,
    private _lightboxEvent: LightboxEvent,
    private _lightbox: Lightbox
  ) {
    _lighboxConfig.disableScrolling = true;
    _lighboxConfig.centerVertically = true;
    _lighboxConfig.wrapAround = true;
  }

  ngOnInit() {
    this.fetchPassengers();
  }

  fetchPassengers() {
    this.queryBuilder();
    this.getPassengers();
  }

  getPassengers() {
    this.passengerService.getPassengers(this.query).toPromise()
      .then(passengerData => {
        this.passengers = passengerData.passengers;
        this.totalPassengers = passengerData.totalPassengers;
        this.fillImages(this.passengers);
      }).catch(err => {
        this.errorMessage = err.message;
      });
  }

  fillImages(passengers) {
    this.images = [];
    passengers.forEach(passenger => {
      this.images.push({
        src: passenger.imageUrl ? passenger.imageUrl : "../../assets/img/default.png",
        caption: `${passenger.firstName} ${passenger.lastName}`,
        thumb: passenger.imageUrl ? passenger.imageUrl : "../../assets/img/default.png"
      });
    });
  }

  clearSearch() {
    this.page = 1;
    this.selectedSortBy = "DESC";
    this.searchText = "";
    this.registrationPeriod = null;
    this.fetchPassengers();
  }

  fetch(pageNum: number) {
    this.page = pageNum;
    this.fetchPassengers();
  }

  queryBuilder() {
    this.offset = (this.page - 1) * this.limit;
    this.query = `limit=${this.limit}&offset=${this.offset}&order=${this.selectedSortBy}&searchText=${this.searchText}`;
    if (this.registrationPeriod != null) {
      const period = {
        from: moment(this.registrationPeriod[0]).startOf("d").valueOf(),
        to: moment(this.registrationPeriod[1]).endOf("d").valueOf()
      };
      this.query += `&registeredFrom=${period.from}&registeredTo=${period.to}`;
    }
    if (this.searchText || this.registrationPeriod || this.selectedSortBy !== "DESC") {
      this.showClearSearchButton = true;
    } else {
      this.showClearSearchButton = false;
    }
  }

  open(index: number): void {
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event: IEvent) => this._onReceivedEvent(event));
    this._lightbox.open(this.images, index);
  }

  _onReceivedEvent(event: IEvent): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }
  }

}

import { Injectable } from "@angular/core";
import { BackendService } from "../backend";

@Injectable()
export class PassengerService {
    constructor(private backendService: BackendService) { }

    getPassengers(query: string) {
        return this.backendService.getBackend(`/passenger?${query}`)
            .map(response => response);
    }

}

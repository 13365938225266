
export interface City {
    id: string;
    name: string;
    geoFence: string;
    geoFenceImageUrl: string;
    countryId: string;
}

export interface Currency {
    id: string;
    name: string;
    currencyCode: string;
    symbol: string;
    symbolPosition: SymbolPosition;
    symbolSize: number;
}

export enum SymbolPosition {
    PREFIX = "PREFIX",
    SUFFIX = "SUFFIX"
}
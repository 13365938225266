import { Component, OnInit } from "@angular/core";
import { City } from './data/city/city';
import { CityService } from "./data/city/city.service";

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.root.component.html"
})
export class AppRootComponent implements OnInit {
  role: string;
  user: string;
  displayName: string;
  cities: City[];
  cityId: string;
  currentCity = {
    id: null,
    name: null
  };

  constructor(private cityService: CityService) { }
  async ngOnInit() {
    // Set current city
    this.cities = await this.cityService.getCities().toPromise();
    this.setCity();
    this.setCurrency();

    this.role = localStorage.getItem("role");
    this.user = localStorage.getItem("name") ? localStorage.getItem("name") : "user";
    this.displayName = this.user.split(/[\s@]+/)[0];
    $(window).resize(() => {
      const windowHeight = $(window).height();
      const windowWidth = $(window).width();
      this.resize(windowWidth, windowHeight);
    });
    $(window).on("load", () => {
      const windowHeight = $(window).height();
      const windowWidth = $(window).width();
      this.resize(windowWidth, windowHeight);
    });
  }

  logout() {
    localStorage.clear();
  }

  setCity() {
    this.cityId = localStorage.getItem("cityId");
    const currentCityId = localStorage.getItem("currentCityId");

    if (currentCityId) {
      // If reload
      this.currentCity = {
        id: this.cities.find(city => city.id === currentCityId).id,
        name: this.cities.find(city => city.id === currentCityId).name
      };
    } else {
      // If first time called init()
      if (this.cityId === "all") {
        // If first time called init()  and   has all access -> Assign first city
        this.currentCity = {
          id: this.cities[0].id,
          name: this.cities[0].name
        };
      } else {
        // If first time called init()  and   has limited access -> Assign city
        this.currentCity = {
          id: this.cities.find(city => city.id === this.cityId).id,
          name: this.cities.find(city => city.id === this.cityId).name
        };
      }
      localStorage.setItem("currentCityId", this.currentCity.id);
    }
  }

  changeCity(cityId: string) {
    localStorage.setItem("currentCityId", cityId);
    location.reload();
  }

  async setCurrency(){
    const currentCityId = localStorage.getItem("currentCityId");
    const currency = await this.cityService.getCurrencyByArea(currentCityId, "CITY").toPromise();
    localStorage.setItem("currency", JSON.stringify(currency));
  }

  resize(width, height) {
    if (width < 1120) {
      // apply minimized classes
      this.minimizedSidebar();
    } else {
      // apply expanded classes
      this.expandedSidebar();
    }
  }

  expandedSidebar() {
    $("#sidebar").removeClass("minimized");
    $("#main").removeClass("main-minimized").addClass("main-expanded");
    $("#nav-toggler").removeClass("toggler-minimized").addClass("toggler-expanded");
    $("#sidebar-dropdown").removeClass("sidebar-dropdown-minimized").addClass("sidebar-dropdown-expanded");
  }

  minimizedSidebar() {
    $("#sidebar").addClass("minimized");
    $("#main").removeClass("main-expanded").addClass("main-minimized");
    $("#nav-toggler").removeClass("toggler-expanded").addClass("toggler-minimized");
    $("#sidebar-dropdown").removeClass("sidebar-dropdown-expanded").addClass("sidebar-dropdown-minimized");
  }

  toggleClick(event) {
    $("#sidebar").toggleClass("minimized");
    $("#main").toggleClass("main-expanded main-minimized");
    $("#nav-toggler").toggleClass("toggler-expanded toggler-minimized");
    $("#sidebar-dropdown").toggleClass("sidebar-dropdown-expanded sidebar-dropdown-minimized ");
  }
}
